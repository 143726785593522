import { Link } from "gatsby";
import React, { FC } from "react";

interface ServiceItemProps {
  title: string;
  description: string;
  slug: string;
  icon: string;
}

const ServiceItem: FC<ServiceItemProps> = ({ description, icon, slug, title }) => {
  return (
    <div className="col-lg-4 col-md-6 col-sm-6">
      <div className="single-solutions-box">
        <div className="icon">
          <img src={icon} alt={title} />
        </div>
        <h3>
          <Link title={title} to={slug} className="cut-text1">
            {title}
          </Link>
        </h3>
        <p className="cut-text4">{description}</p>

        <Link className="view-details-btn" to={slug}>
          View Details
        </Link>
      </div>
    </div>
  );
};

export default ServiceItem;
