import { SEOType, ServiceCardOptions } from "@/types";
import React, { FC } from "react";
import Layout from "../App/Layout";
import SEO from "../SEO";
import ServiceItem from "../Shared/Design/ServiceItem";
import BreadCrumb from "../Shared/extra/BreadCrumb";

interface ServicesListPageProps {
  props: {
    seo: SEOType;
    services: ServiceCardOptions[];
    footerContent?: any;
  };
}

const ServicesListPage: FC<ServicesListPageProps> = ({
  props: { seo, services, footerContent },
}) => {
  return (
    <Layout footerContent={footerContent}>
      <SEO contentfulSeo={seo} />
      <BreadCrumb
        pageTitle="Services"
        paths={[
          { path: "/", pathName: "Home" },
          { path: "/services/", pathName: "Services" },
        ]}
      />

      <div className="courses-area ptb-100 bg-fafafb">
        <div className="container">
          <div className="row">
            {services.map(
              ({
                id,
                title,
                description: { description },
                slug,
                icon: {
                  file: { url },
                },
              }) => {
                return (
                  <ServiceItem
                    key={id}
                    title={title}
                    description={description}
                    slug={"/" + slug}
                    icon={url}
                  />
                );
              },
            )}
          </div>
        </div>

        <div className="vector-shape6">
          <img src="/images/shape/vector-shape6.png" alt="services" />
        </div>
      </div>
    </Layout>
  );
};

export default ServicesListPage;
