import ServicesListPage from "@/components/Services";
import { graphql, StaticQuery } from "gatsby";
import React from "react";

export default function Services(): JSX.Element {
  return (
    <StaticQuery
      query={graphql`
        query GET_CONTENT_OF_SERVICES_LIST {
          contentfulServicesPage {
            # SEO Tags
            seo {
              metaTitle
              metaDescription
              metaUrl
              metaAuthor
              metaKeywords
              metaImage {
                file {
                  url
                }
              }
            }

            #SERVICES
            services {
              id
              icon {
                file {
                  url
                }
              }
              slug
              title
              description {
                description
              }
            }

            # Footer Content
            footerContent {
              raw
            }
          }
        }
      `}
      render={({ contentfulServicesPage: { seo, services, footerContent } }) => (
        <ServicesListPage props={{ seo, services, footerContent }} />
      )}
    />
  );
}
